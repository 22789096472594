import React from 'react';
import {useTrail, config} from 'react-spring';
import AnimatedSplitText from 'utils/AnimatedSplitText';
import styles from './styles.module.scss';

const text = 'Seventh';

const Seventh = () => {
	const trail = useTrail(text.length, {
		from: {
			display: 'inline-block',
			transform: 'rotate(0deg)'
		},
		to: async next => {
			while (1) {
				await next({
					display: 'inline-block',
					transform: 'rotate(20deg)'
				});
			}
		},
		config: config.gentle,
		reset: true
	});

	return <AnimatedSplitText trail={trail}>{text}</AnimatedSplitText>;
};

export default Seventh;
