import React from 'react';
import {useSpring, config, animated} from 'react-spring';
import styles from './styles.module.scss';

const text = 'Sixth';

const Sixth = () => {
	const props = useSpring({
		from: {
			transform: 'rotateY(0deg) rotateZ(0deg)'
		},
		to: async next => {
			while (1) {
				await next({
					transform: 'rotateY(360deg) rotateZ(360deg)'
				});
			}
		},
		config: {
			duration: 1000
		},
		reset: true
	});

	return (
		<animated.div style={props} className={styles.sixth}>
			{text}
		</animated.div>
	);
};

export default Sixth;
