import React from 'react';
import {useSpring, animated} from 'react-spring';
import styles from './styles.module.scss';

const Fourth = () => {
	const props = useSpring({
		from: {
			backgroundImage: 'linear-gradient(to right, #b5fdc1 -200%, #ababab)'
		},
		to: async next => {
			while (1) {
				await next({
					backgroundImage:
						'linear-gradient(to right, #b5fdc1 200%, #ababab)'
				});
			}
		},
		config: {duration: 1000},
		reset: true
	});

	return (
		<animated.div style={props} className={styles.text}>
			Fourth
		</animated.div>
	);
};

export default Fourth;
