import React from 'react';
import {TypeContainer} from 'components/molecules';
import {Layout} from 'components/pageLayouts';
import {Third, Fourth, Fifth, Sixth, Seventh, Eigth} from 'type';

const Type = () => {
	return (
		<Layout>
			<div className={'row'}>
				<TypeContainer backgroundColor={'#ababab'}>
					<Eigth />
				</TypeContainer>
				<TypeContainer backgroundColor={'#ababab'}>
					<Seventh />
				</TypeContainer>
				<TypeContainer backgroundColor={'#ababab'}>
					<Sixth />
				</TypeContainer>
				<TypeContainer backgroundColor={'#ababab'}>
					<Fifth />
				</TypeContainer>
				<TypeContainer backgroundColor={'#ababab'}>
					<Fourth />
				</TypeContainer>
				<TypeContainer backgroundColor={'#ababab'}>
					<Third />
				</TypeContainer>
			</div>
		</Layout>
	);
};

export default Type;
