import React from 'react';
import {config, animated, useSpring} from 'react-spring';
import styles from './styles.module.scss';

const text = 'Eigth';

const AnimFeMorphology = animated('feMorphology');

const Eigth = () => {
	const {radius} = useSpring({
		from: {
			radius: '1'
		},
		to: async next => {
			while (1) {
				await next({
					radius: '2'
				});
			}
		},
		config: {duration: 1000},
		reset: true
	});

	return (
		<>
			<svg display='none'>
				<defs>
					<filter id='pictureFilter'>
						<AnimFeMorphology operator='erode' radius={radius} />
					</filter>
				</defs>
			</svg>
			<div className={styles.div}>{text}</div>
		</>
	);
};

export default Eigth;
