import React from 'react';
import AnimatedSplitText from 'utils/AnimatedSplitText';
import {useTrail} from 'react-spring';

const text = 'Third';

const Third = () => {
	const trail = useTrail(text.length, {
		from: {
			color: 'blue'
		},
		to: async next => {
			while (1) {
				await next({
					color: 'green'
				});
			}
		},
		config: {mass: 5, tension: 2000, friction: 200},
		reset: true
	});

	return <AnimatedSplitText trail={trail}>{text}</AnimatedSplitText>;
};

export default Third;
