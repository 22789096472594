import React from 'react';
import {a} from 'react-spring';

const AnimatedSplitText = ({children, role, trail, className}) => {
	return (
		<div aria-label={children} role={role} className={className}>
			{trail.map((style, index) => (
				<a.span aria-hidden='true' key={index} style={style}>
					{children[index]}
				</a.span>
			))}
		</div>
	);
};

export default AnimatedSplitText;
