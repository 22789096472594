import React from 'react';
import AnimatedSplitText from 'utils/AnimatedSplitText';
import {useTrail, config} from 'react-spring';
import styles from './styles.module.scss';

const text = 'Fifth';

const Fifth = () => {
	const trail = useTrail(text.length, {
		from: {
			fontVariationSettings: `'slnt' -15, 'CASL' 0, 'CRSV' 0,  'MONO' 0`,
			fontWeight: '300'
		},
		to: async next => {
			while (1) {
				await next({
					fontVariationSettings: `'slnt' 0, 'CASL' 1, 'CRSV' 1, 'MONO' 1`,
					fontWeight: '1000'
				});
			}
		},
		config: config.gentle,
		reset: true
	});

	return (
		<AnimatedSplitText trail={trail} className={styles.fifth}>
			{text}
		</AnimatedSplitText>
	);
};

export default Fifth;
